<template>
	<b-modal
		id="expenseCarouselView"
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		size="xxl"
		centered
		cancel-variant="light"
		@hidden="emitEventClose"
		no-close-on-backdrop
		no-close-on-esc
	>
		<div class="w-100" style="height: 100%; display: flex">
			<loading :active.sync="isLoading" is-full-page loader="bars" color="#00A09C" :width="64" />
			<!-- <pre>{{ isMyExpense }}</pre> -->
			<expense-carousel-view-card
				ref="expense-carousel-view-card"
				:expense="expense ? expense : { id: 0, type: 0 }"
				:expense-item="currExpenseItem"
				:expense-items="expenseItems"
				:is-my-expense="isMyExpense"
				:can-make-validation="canMakeValidation"
				:can-only-make-split-item="canOnlyMakeSplitItem"
				:reload-data-after-img-inserted="reloadDataAfterImgInserted"
				@expense-carousel-view-card:reload="onDataTableReloaded"
				@expense-carousel-view-card:expenseItemRejected="onExpenseItemRejected"
				@expense-carousel-view-card:redirectAndReload="onRedirectedAndReloaded"
				@expense-carousel-view-card:closeModal="onModalClosed"
				style="height: 100%; flex: 1"
			/>
			<script-export-modal
				v-model="isScriptExpenseReportOpen"
				:items="scripts"
				:custom="customReport"
				export-type="expense"
				@script-export-modal:loading="handleLoading"
				@script-export-modal:closed="expenseRerpotOnClosed"
			/>
			<reject-expense-modal
				v-if="expense"
				ref="reject-modal"
				v-model="isRejectExpenseOpened"
				:id="expense.id"
				:type-name="EXPENSE_TREE_NAME.PARENT"
				@reject-expense-item-modal:close="onRejectExpenseModalClosed"
				@reject-expense-item-modal:redirectAndReloadExpenseDatatable="onRedirectedAndReloaded"
			/>
		</div>
		<template #modal-title>
			<b-row>
				<b-col cols="4">
					<div style="font-size: 1.25rem">
						{{ expense ? FormMSG(3, 'Expense sheet review') : FormMSG(11 + expenseType, 'Edit ' + EXPENSE_NAME[expenseType]) }}
					</div>
				</b-col>
				<b-col cols="4" class="text-center">
					<div style="font-size: 1.25rem">
						<span class="text-uppercase">{{
							expense ? FormMSG(4 + expense.type, EXPENSE_NAME[expense.type]) : FormMSG(4 + expenseType, EXPENSE_NAME[expenseType])
						}}</span>
						#{{ expense ? expense.id : expenseItem.id }}
					</div>
				</b-col>
				<b-col cols="4"> </b-col>
			</b-row>
		</template>
		<template #modal-footer>
			<div class="pt-1 w-100 d-flex justify-content-end align-items-center">
				<b-dropdown
					id="report-carousel-expense"
					split
					split-variant="outline-primary"
					dropup
					:text="FormMSG(14, 'Report')"
					size="sm"
					class="w-125-px mr-3"
					variant="primary"
					v-if="expense"
				>
					<b-dropdown-item-button @click="downloadXls">
						<div class="d-flex justify-content-start align-items-center">
							<component :is="getLucideIcon(ICONS.DOWNLOAD.name)" :size="20" class="mr-2" />
							<div style="margin-top: 1px">{{ FormMSG(15, 'Download expense sheet') }}</div>
						</div>
					</b-dropdown-item-button>
					<b-dropdown-item-button @click="sendXlsByEmail">
						<div class="d-flex justify-content-start align-items-center">
							<component :is="getLucideIcon(ICONS.SEND.name)" :size="20" class="mr-2" />
							<div style="margin-top: 1px">{{ FormMSG(16, 'Send me by email') }}</div>
						</div>
					</b-dropdown-item-button>
				</b-dropdown>
				<template v-if="expense">
					<b-button
						size="sm"
						variant="outline-danger"
						style="margin-top: 0"
						class="w-250-px d-flex justify-content-center align-items-center"
						@click="rejectAllExpenseItem"
						block
						v-if="canMakeValidation"
					>
						<component :is="getLucideIcon(ICONS.X.name)" :size="18" class="mr-2" />
						<div style="margin-top: 2px">{{ FormMSG(10, 'Reject all expense sheet') }}</div>
					</b-button>
					<b-button
						size="sm"
						variant="custom-primary-blue"
						class="w-350-px mx-3 ml-6"
						style="margin-top: 0"
						:disabled="watingSubmitForm"
						@click="validateExpense"
						block
						v-if="canMakeValidation"
					>
						<div class="d-flex justify-content-center align-items-center">
							<b-spinner v-show="watingSubmitForm" small />
							<div class="d-flex justify-content-center align-items-center" :class="`${watingSubmitForm ? 'pl-2' : ''}`" style="margin-top: 1px">
								<component v-if="!watingSubmitForm" :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" :size="18" class="mr-2" />
								<div style="margin-top: 2px; letter-spacing: 0.05rem">{{ FormMSG(2, 'Validate Expense without rejected items') }}</div>
							</div>
						</div>
					</b-button>
				</template>
				<b-button v-else style="margin-top: 0" size="sm" variant="custom-primary-blue" class="w-138-px mx-3 ml-6" @click="saveExpenseItem" block>
					{{ FormMSG(41, 'Save') }}
				</b-button>
				<b-button
					style="margin-top: 0"
					size="sm"
					variant="custom-outline-gray"
					class="w-138-px"
					:disabled="watingSubmitForm"
					@click="emitEventClose"
					block
				>
					{{ FormMSG(1, 'Close') }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import { store } from '@/store';
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import ExpenseCarouselViewCard from '@/components/ExpenseService/ExpenseCarouselViewCard';
import _ from 'lodash';
import { EXPENSE_TYPE, EXPENSE_NAME, EXPENSE_TREE_NAME, FILENAME_REPORT, OUTPUT_FORMAT_EXPORT } from '@/shared/constants';
import { mapActions } from 'vuex';
import { validateExpense } from '@/cruds/expense.crud';
import RejectExpenseModal from '@/components/ExpenseService/CarouselView/RejectExpenseItemModal';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import { getScripts } from '@/cruds/script.crud';
import { getFileExtension } from '@/shared/helpers';
import { expenseReport } from '@/cruds/export.crud';
import Loading from 'vue-loading-overlay';

export default {
	name: 'ExpenseCarouselViewModal',
	mixins: [LanguageMessages, GlobalMixin],
	components: {
		ExpenseCarouselViewCard,
		RejectExpenseModal,
		ScriptExportModal,
		Loading
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		},
		licenseId() {
			return +store.getCurrentProjectConfig().licenseID;
		},
		projectId() {
			return +store.getCurrentProjectConfig().id;
		}
	},
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		title: {
			type: String,
			required: false,
			default: ''
		},
		expenseType: {
			type: Number,
			required: false,
			default: 0
		},
		expense: {
			type: Object,
			required: false,
			default: null
		},
		expenseItem: {
			type: Object,
			required: false,
			default: null
		},
		expenseItems: {
			type: Array,
			required: true,
			default: () => []
		},
		canMakeValidation: {
			type: Boolean,
			required: false,
			default: false
		},
		reloadDataAfterImgInserted: {
			type: Boolean,
			required: false,
			default: true
		},
		canOnlyMakeSplitItem: {
			type: Boolean,
			required: false,
			default: false
		},
		isMyExpense: {
			type: Boolean,
			required: false,
			default: null
		}
	},
	data() {
		return {
			watingSubmitForm: false,
			EXPENSE_TYPE,
			EXPENSE_NAME,
			isRejectExpenseOpened: false,
			EXPENSE_TREE_NAME,
			currExpenseItem: null,
			scriptTypeId: 0,
			scripts: [],
			customReport: null,
			isLoading: false,
			isScriptExpenseReportOpen: false,
			index: 1
		};
	},
	watch: {
		expenseItem: {
			handler(newVal) {
				if (!_.isNil(newVal)) {
					this.index = newVal.index;
					this.currExpenseItem = newVal;
				}
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		emitEventClose() {
			this.$emit('expense-carousel-view-modal:closed');
		},
		async validateExpense() {
			const action = async () => {
				await validateExpense(+this.expense.id, '')
					.then(async (record) => {
						await this.getNotifications();
						this.$emit('expense-carousel-view-modal:reload');
						this.$emit('expense-carousel-view-modal:redirect');
						this.emitEventClose();
					})
					.catch((error) => {
						console.log(error);
					});
			};
			this.confirmModal(action, this.FormMSG(8, 'Are you sure to validate all items ?'), 'success');
		},
		rejectAllExpenseItem() {
			const action = async () => {
				this.isRejectExpenseOpened = true;
			};
			this.confirmModal(action, this.FormMSG(9, 'Are you sure to reject all expense items ?'), 'danger');
		},
		onDataTableReloaded(index) {
			this.$emit('expense-carousel-view-modal:reload', index);
		},
		onExpenseItemRejected(payload) {
			this.$emit('expense-carousel-view-modal:expenseItemRejected', payload);
		},
		onRedirectedAndReloaded() {
			this.$emit('expense-carousel-view-modal:reload');
			this.$emit('expense-carousel-view-modal:redirect');
		},
		onModalClosed() {
			this.emitEventClose();
		},
		onRejectExpenseModalClosed() {
			this.isRejectExpenseOpened = false;
		},
		saveExpenseItem() {
			this.$refs['expense-carousel-view-card'].saveExpenseItem();
			// this.$emit('expense-carousel-view-modal:save', {});
		},
		handleLoading(payload) {
			if (payload) {
				this.isLoading = false;
			}
		},
		expenseRerpotOnClosed() {
			this.isScriptExpenseReportOpen = false;
			this.isLoading = false;
		},
		async manageExpenseReport(subjectId, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_EXPENSE, script.id, script.fileName);

			await expenseReport(
				{
					subjectId,
					sendEmail,
					activeStatus: true
				},
				script.id
			)
				.then((result) => {
					if (!sendEmail) {
						this.forceDownload(result, fileName + '.' + script.ext);
					}
				})
				.catch((e) => {
					console.error(e);
				})
				.finally(() => (this.isLoading = false));
		},
		async downloadXls() {
			this.scripts = await getScripts(this.licenseId, this.projectId, this.scriptTypeId, true);
			const id = +this.expense.id;

			if (this.scripts.length === 0) {
				this.isLoading = true;
				await this.manageExpenseReport(id).finally(() => {
					this.isLoading = false;
				});
			} else if (this.scripts.length === 1) {
				this.isLoading = true;
				await this.manageExpenseReport(id, {
					id: this.scripts[0].id,
					fileName: this.scripts[0].customExportFileName,
					ext: this.getExtensionScript(
						OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
						this.scripts[0].customExtension,
						getFileExtension(this.scripts[0].fileName)
					)
				}).finally(() => {
					this.isLoading = false;
				});
			} else {
				this.isScriptExpenseReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: false
				};
			}
		},
		async sendXlsByEmail() {
			this.scripts = await getScripts(this.licenseId, this.projectId, this.scriptTypeId, true);
			const id = +this.expense.id;

			if (this.scripts.length === 0) {
				this.isLoading = true;
				await this.manageExpenseReport(id, 0, true).then(() => {
					this.createToastForMobile(this.FormMSG(11, 'Success'), this.FormMSG(16, 'The document has been sent to your email address.'));
					this.isLoading = false;
				});
			} else if (this.scripts.length === 1) {
				this.isLoading = true;
				await this.manageExpenseReport(id, { id: this.scripts[0].id }, true).then(() => {
					this.createToastForMobile(this.FormMSG(11, 'Success'), this.FormMSG(16, 'The document has been sent to your email address.'));
					this.isLoading = false;
				});
			} else {
				this.isScriptExpenseReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: true
				};
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.modal-header .close {
	width: 30px; /* Adjust the width */
	height: 30px; /* Adjust the height */
	line-height: 30px; /* Vertically center the 'X' */
	font-size: 20px; /* Increase the font size */
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "expenseCarouselView",
        "header-class": "header-class-modal-doc-package",
        size: "xxl",
        centered: "",
        "cancel-variant": "light",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: { hidden: _vm.emitEventClose },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _c("div", { staticStyle: { "font-size": "1.25rem" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(
                            _vm.expense
                              ? _vm.FormMSG(3, "Expense sheet review")
                              : _vm.FormMSG(
                                  11 + _vm.expenseType,
                                  "Edit " + _vm.EXPENSE_NAME[_vm.expenseType]
                                )
                          ) +
                          "\n\t\t\t\t"
                      ),
                    ]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "4" } },
                    [
                      _c("div", { staticStyle: { "font-size": "1.25rem" } }, [
                        _c("span", { staticClass: "text-uppercase" }, [
                          _vm._v(
                            _vm._s(
                              _vm.expense
                                ? _vm.FormMSG(
                                    4 + _vm.expense.type,
                                    _vm.EXPENSE_NAME[_vm.expense.type]
                                  )
                                : _vm.FormMSG(
                                    4 + _vm.expenseType,
                                    _vm.EXPENSE_NAME[_vm.expenseType]
                                  )
                            )
                          ),
                        ]),
                        _vm._v(
                          "\n\t\t\t\t\t#" +
                            _vm._s(
                              _vm.expense ? _vm.expense.id : _vm.expenseItem.id
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ]),
                    ]
                  ),
                  _c("b-col", { attrs: { cols: "4" } }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "pt-1 w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _vm.expense
                    ? _c(
                        "b-dropdown",
                        {
                          staticClass: "w-125-px mr-3",
                          attrs: {
                            id: "report-carousel-expense",
                            split: "",
                            "split-variant": "outline-primary",
                            dropup: "",
                            text: _vm.FormMSG(14, "Report"),
                            size: "sm",
                            variant: "primary",
                          },
                        },
                        [
                          _c(
                            "b-dropdown-item-button",
                            { on: { click: _vm.downloadXls } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-start align-items-center",
                                },
                                [
                                  _c(
                                    _vm.getLucideIcon(_vm.ICONS.DOWNLOAD.name),
                                    {
                                      tag: "component",
                                      staticClass: "mr-2",
                                      attrs: { size: 20 },
                                    }
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "1px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(
                                            15,
                                            "Download expense sheet"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "b-dropdown-item-button",
                            { on: { click: _vm.sendXlsByEmail } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-start align-items-center",
                                },
                                [
                                  _c(_vm.getLucideIcon(_vm.ICONS.SEND.name), {
                                    tag: "component",
                                    staticClass: "mr-2",
                                    attrs: { size: 20 },
                                  }),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "1px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(16, "Send me by email")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.expense
                    ? [
                        _vm.canMakeValidation
                          ? _c(
                              "b-button",
                              {
                                staticClass:
                                  "w-250-px d-flex justify-content-center align-items-center",
                                staticStyle: { "margin-top": "0" },
                                attrs: {
                                  size: "sm",
                                  variant: "outline-danger",
                                  block: "",
                                },
                                on: { click: _vm.rejectAllExpenseItem },
                              },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.X.name), {
                                  tag: "component",
                                  staticClass: "mr-2",
                                  attrs: { size: 18 },
                                }),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-top": "2px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(
                                          10,
                                          "Reject all expense sheet"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.canMakeValidation
                          ? _c(
                              "b-button",
                              {
                                staticClass: "w-350-px mx-3 ml-6",
                                staticStyle: { "margin-top": "0" },
                                attrs: {
                                  size: "sm",
                                  variant: "custom-primary-blue",
                                  disabled: _vm.watingSubmitForm,
                                  block: "",
                                },
                                on: { click: _vm.validateExpense },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-center align-items-center",
                                  },
                                  [
                                    _c("b-spinner", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.watingSubmitForm,
                                          expression: "watingSubmitForm",
                                        },
                                      ],
                                      attrs: { small: "" },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-center align-items-center",
                                        class: `${
                                          _vm.watingSubmitForm ? "pl-2" : ""
                                        }`,
                                        staticStyle: { "margin-top": "1px" },
                                      },
                                      [
                                        !_vm.watingSubmitForm
                                          ? _c(
                                              _vm.getLucideIcon(
                                                _vm.ICONS.CHECK_SQUARE.name
                                              ),
                                              {
                                                tag: "component",
                                                staticClass: "mr-2",
                                                attrs: { size: 18 },
                                              }
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-top": "2px",
                                              "letter-spacing": "0.05rem",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  2,
                                                  "Validate Expense without rejected items"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _c(
                        "b-button",
                        {
                          staticClass: "w-138-px mx-3 ml-6",
                          staticStyle: { "margin-top": "0" },
                          attrs: {
                            size: "sm",
                            variant: "custom-primary-blue",
                            block: "",
                          },
                          on: { click: _vm.saveExpenseItem },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t" +
                              _vm._s(_vm.FormMSG(41, "Save")) +
                              "\n\t\t\t"
                          ),
                        ]
                      ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      staticStyle: { "margin-top": "0" },
                      attrs: {
                        size: "sm",
                        variant: "custom-outline-gray",
                        disabled: _vm.watingSubmitForm,
                        block: "",
                      },
                      on: { click: _vm.emitEventClose },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(1, "Close")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                2
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "w-100",
          staticStyle: { height: "100%", display: "flex" },
        },
        [
          _c("loading", {
            attrs: {
              active: _vm.isLoading,
              "is-full-page": "",
              loader: "bars",
              color: "#00A09C",
              width: 64,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          }),
          _c("expense-carousel-view-card", {
            ref: "expense-carousel-view-card",
            staticStyle: { height: "100%", flex: "1" },
            attrs: {
              expense: _vm.expense ? _vm.expense : { id: 0, type: 0 },
              "expense-item": _vm.currExpenseItem,
              "expense-items": _vm.expenseItems,
              "is-my-expense": _vm.isMyExpense,
              "can-make-validation": _vm.canMakeValidation,
              "can-only-make-split-item": _vm.canOnlyMakeSplitItem,
              "reload-data-after-img-inserted": _vm.reloadDataAfterImgInserted,
            },
            on: {
              "expense-carousel-view-card:reload": _vm.onDataTableReloaded,
              "expense-carousel-view-card:expenseItemRejected":
                _vm.onExpenseItemRejected,
              "expense-carousel-view-card:redirectAndReload":
                _vm.onRedirectedAndReloaded,
              "expense-carousel-view-card:closeModal": _vm.onModalClosed,
            },
          }),
          _c("script-export-modal", {
            attrs: {
              items: _vm.scripts,
              custom: _vm.customReport,
              "export-type": "expense",
            },
            on: {
              "script-export-modal:loading": _vm.handleLoading,
              "script-export-modal:closed": _vm.expenseRerpotOnClosed,
            },
            model: {
              value: _vm.isScriptExpenseReportOpen,
              callback: function ($$v) {
                _vm.isScriptExpenseReportOpen = $$v
              },
              expression: "isScriptExpenseReportOpen",
            },
          }),
          _vm.expense
            ? _c("reject-expense-modal", {
                ref: "reject-modal",
                attrs: {
                  id: _vm.expense.id,
                  "type-name": _vm.EXPENSE_TREE_NAME.PARENT,
                },
                on: {
                  "reject-expense-item-modal:close":
                    _vm.onRejectExpenseModalClosed,
                  "reject-expense-item-modal:redirectAndReloadExpenseDatatable":
                    _vm.onRedirectedAndReloaded,
                },
                model: {
                  value: _vm.isRejectExpenseOpened,
                  callback: function ($$v) {
                    _vm.isRejectExpenseOpened = $$v
                  },
                  expression: "isRejectExpenseOpened",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }